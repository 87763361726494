import feather from "feather-icons";

// THIS FILE IS FOR DEMO PURPOSES ONLY
// AND CAN BE REMOVED AFTER PICKING A STYLE
// JUST BEFORE THE </head> CLOSING TAG

// Set cookie
function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// Get cookie
function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// Get query parameter
function getQueryParameter(name) {
  let queryParameter = undefined;
  location.search
    .substr(1)
    .split("&")
    .some(function(item) {
      // returns first occurence and stops
      return (
        item.split("=")[0] == name && (queryParameter = item.split("=")[1])
      );
    });
  return queryParameter;
}

// Get current theme
function getCurrentTheme() {
  const cookie = getCookie("theme");
  const queryParameter = getQueryParameter("theme");

  if (queryParameter) {
    setCookie("theme", queryParameter, 7);
    return queryParameter;
  }

  return cookie ? cookie : "modern";
}

// Append theme style-tag to <head>
const link = document.createElement("link");
link.href = "css/" + getCurrentTheme() + ".css";
link.type = "text/css";
link.rel = "stylesheet";
document.getElementsByTagName("head")[0].appendChild(link);

// Append settings sidebar after page load
document.addEventListener("DOMContentLoaded", function() {
  const html = `<div class="settings d-none">
    <div class="settings-toggle toggle-settings">
      <i class="align-middle" data-feather="search"></i>
    </div>
    <form id="global_search_form" class="wizard-primary create-account-form" action="javascript:void(0)">
      <div class="settings-panel">
        <div class="settings-content js-simplebar">
          <div class="settings-title">
            <button type="button" class="close float-right toggle-settings" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>

            <h4>Search</h4>
          </div>

          <div class="settings-section">
            <small class="d-block text-uppercase font-weight-bold text-muted mb-2">By Property Name</small>
            <div class="form-group ">
                <input id="search_proeprty_name" name="search_proeprty_name" type="text" class="form-control" placeholder="Search by property name">
            </div>
            <small class="d-block text-uppercase font-weight-bold text-muted mb-2">By Tenant</small>
            <div class="form-group ">
                <input id="search_proeprty_name" name="search_proeprty_name" type="text" class="form-control" placeholder="Search by tenant name">
            </div>
            <small class="d-block text-uppercase font-weight-bold text-muted mb-2">By Lease</small>
            <div class="form-group ">
                <input id="search_proeprty_name" name="search_proeprty_name" type="text" class="form-control" placeholder="Search by lease date">
            </div>
          </div>

          <div class="settings-section">
            <a href="javascript:void(0)" class="btn btn-primary btn-block btn-lg"><i class="fas fa-search"></i> Search</a>
          </div>

        </div>
      </div>
    </form>
  </div>`;

  // Append html to body
  $("body").append(html);

  // Replace feather icons
  feather.replace();

  // Toggle settings
  $(".toggle-settings").on("click", function(e) {
    e.preventDefault();

    $(".settings").toggleClass("open");
  });

  // Close sidebar on outside click
  $(document).on('click', function (e) {
    if ($(e.target).closest(".settings").length === 0) {
      $(".settings").removeClass("open");
    }
  });
});
