// Markdown editor functions

window.initialize_markdown = function(element) {
    return new SimpleMDE({
        element: document.getElementById(element),
        hideIcons: ["side-by-side","fullscreen","image","code","link","guide"],
        placeholder: "Leave a comment ...",
        showIcons: ["strikethrough"],
        styleSelectedText: false,
        spellChecker: false
    });
}

window.markdown_drag_drop = function(element) {
    element.codemirror.on("dragover", function(){
        $('.CodeMirror, .markdown_file_upload').css('border','1px dotted #FF0000');
    });

    element.codemirror.on("dragleave", function(){
        $('.CodeMirror, .markdown_file_upload').css('border','1px solid #ddd');
    });

    element.codemirror.on("drop", function(data, e){
        $('.CodeMirror, .markdown_file_upload').css('border','1px solid #ddd');
        var files = e.dataTransfer.files;
        var url = api_url+"tickets/upload";
        upload_markdown_files(url, files, element);
    });
}

// window.markdown_attachment = function(simplemde) {
//     inlineAttachment.editors.codemirror4.attach(simplemde.codemirror, {
//         onFileReceived: function(file) {
//             var sizeInMB = (file.size / (1024*1024)).toFixed(2);
//             if(sizeInMB > max_file_size) {
//                 var response = {
//                     "response_data": {
//                         "success": false,
//                         "message": 'Maximum file size should be 8MB'
//                     }
//                 };
//                 display_error_message(response);
//                 return false;
//             } 
//         },
//         onFileUploadResponse: function(xhr) {
//             var response = JSON.parse(xhr.responseText);
//             if(response.response_data.success == true) {
//                 var filename = response[this.settings.jsonFieldName];

//                 var name = filename.substring(filename.lastIndexOf('/')+1);
//                 var ext = name.split('.')[1];

//                 //console.log('===    '+filename);
//                 //console.log('???    '+this.filenameTag);
//                 //console.log('AAA    '+this.settings.urlText);

//                 if (response && filename) {
//                     var newValue;
//                     if (typeof this.settings.urlText === 'function') {
//                         newValue = this.settings.urlText.call(this, filename, response);
//                     } else {
//                         //if(ext == 'zip' || ext == 'pdf') {
//                         if(ext == 'zip') {
//                             newValue = this.settings.urlText.replace('![Image]({filename})\n', '['+name+']('+filename+')\n');
//                         } else {
//                             newValue = this.settings.urlText.replace(this.filenameTag, filename);
//                         }
//                     }

//                     //console.log('BBB    '+newValue);

//                     var text = this.editor.getValue().replace(this.lastValue, newValue);
//                     this.editor.setValue(text);
//                     this.settings.onFileUploaded.call(this, filename);
//                 }
//             } else {
//                 //display_error_message(response);
//             }
//             return false;
//         },
//         uploadUrl: api_url+"tickets/upload",
//         uploadFieldName: 'attachment',
//         jsonFieldName: 'filename',
//         urlText: "![Image]({filename})\n",
//         progressText: "![Uploading file...]()",
//         extraHeaders: {
//             'Authorization' : accessToken
//         }
//     });
// }

window.markdown_add_selectfile = function(simplemde) {
    $(".markdown_upload_link").on('click', function(e){
        e.preventDefault();
        $(".markdown_upload_input").trigger('click');
    });

    $(".markdown_upload_input").on('change',function(){
        var url = api_url+"tickets/upload";
        var files = $(".markdown_upload_input").prop('files');
        upload_markdown_files(url, files, simplemde);
    });
}

window.markdown_view_comment_selectfile = function(simplemde) {
    $(".markdown_view_upload_link").on('click', function(e){
        e.preventDefault();
        $(".markdown_view_upload_input").trigger('click');
    });

    $(".markdown_view_upload_input").on('change',function(){
        var url = api_url+"tickets/upload";
        var files = $(".markdown_view_upload_input").prop('files');
        upload_markdown_files(url, files, simplemde);
    });
}

window.markdown_main_comment_selectfile = function(simplemde) {
    $(".markdown_main_upload_link").on('click', function(e){
        e.preventDefault();
        $(".markdown_main_upload_input").trigger('click');
    });

    $(".markdown_main_upload_input").on('change',function(){
        var url = api_url+"tickets/upload";
        var files = $(".markdown_main_upload_input").prop('files');
        upload_markdown_files(url, files, simplemde);
    });
}

window.markdown_sub_comment_selectfile = function(simplemde,id) {
    $(".markdown_sub_upload_link_"+id).on('click', function(e){
        e.preventDefault();
        $(".markdown_sub_upload_input_"+id).trigger('click');
    });

    $(".markdown_sub_upload_input_"+id).on('change',function(){
        var url = api_url+"tickets/upload";
        var files = $(".markdown_sub_upload_input_"+id).prop('files');
        upload_markdown_files(url, files, simplemde);
    });
}

window.upload_markdown_files = function(url, files, simplemde) {
    if (! files.length) {
        return;
    }

    // Check file extension

    for (i = 0; i < files.length; i++) {
        var name = files[i]['name'].substring(files[i]['name'].lastIndexOf('/')+1);
        var ext = name.substring(name.lastIndexOf('.')+1);
        
        if(ext !== "jpg" && ext !== "jpeg" && ext !== "zip" && ext !== "pdf")  {
            if($.inArray(ext, ['jpg','jpeg','zip','JPG','JPEG','pdf','PDF']) == -1){
                var response = {
                    "response_data": {
                        "success": false,
                        "message": 'Please select file with extension jpg, jpeg, pdf or zip.'
                    }
                };
                display_error_message(response);
                return false;
            }
        }

        var sizeInMB = (files[i]['size'] / (1024*1024)).toFixed(2);
        if(sizeInMB > max_file_size) {
            var response = {
                "response_data": {
                    "success": false,
                    "message": 'Maximum file size should be 8MB'
                }
            };
            display_error_message(response);
            return false;
        } 
    }

    // Start uploading file

    //sweet_alert_loading_box('Please wait','Uploading file is in progress...');
    openLoadingDialog($('.CodeMirror-cursor'));
    var pos = simplemde.codemirror.getCursor();
    simplemde.codemirror.setSelection(pos, pos);

    var data = new FormData();
    var i = 0;
    var completed = 0;
    
    for (i = 0; i < files.length; i++) {
        data.append('attachment', files[i]);
        $.ajax({
            url: url,
            type: 'POST',
            contentType: false,
            data: data,
            processData: false,
            cache: false,
            dataType: 'json',
            beforeSend: function(xhr) {
                xhr.setRequestHeader('Authorization', accessToken);
            },
            success: function(response) {
                if(response.response_data.success == true) {
                    var filename = response.filename;
                    var name = filename.substring(filename.lastIndexOf('/')+1);
                    var zip_name = name.split('?')[0];
                    var ext = zip_name.split('.')[1];
                    // ext = ext.split('?')[0];
                    //if(ext == 'zip' || ext == 'pdf') {
                    if(ext == 'pdf' || ext == 'zip') {
                        simplemde.codemirror.replaceSelection('['+zip_name+']('+response.filename+')\n');
                    } else {
                        simplemde.codemirror.replaceSelection('![Image]('+response.filename+')\n');
                    }
                } else {
                    //display_error_message(response);
                }
            },
            complete: function() {
                completed++;
                if (completed === files.length) {
                    //swal.close();
                }
            }
        });
    }
}

window.parse_markdown = function(id) {
    var text = document.getElementById(id).innerHTML;
    var target = document.getElementById(id);
    var converter = new showdown.Converter();
    var html = converter.makeHtml(text);
    target.innerHTML = html;
}

window.parse_markdown_to_html = function(data) {
    var converter = new showdown.Converter();
    var html = converter.makeHtml(data);
    return html;
}

// window.upload_drag_file = function(url, file, simplemde) {
//     // if (! files.length) {
//     //     return;
//     // }

//     // Check file extension

//     var name = file['name'].substring(file['name'].lastIndexOf('/')+1);
//     var ext = name.split('.')[1];
    
//     if(ext !== "jpg" && ext !== "jpeg" && ext !== "zip")  {
//         if($.inArray(ext, ['jpg','jpeg','zip','JPG','JPEG']) == -1){
//             var response = {
//                 "response_data": {
//                     "success": false,
//                     "message": 'Please select file with extension jpg, jpeg or zip.'
//                 }
//             };
//             display_error_message(response);
//             return false;
//         }

//         var sizeInMB = (file['size'] / (1024*1024)).toFixed(2);
//         if(sizeInMB > max_file_size) {
//             var response = {
//                 "response_data": {
//                     "success": false,
//                     "message": 'Maximum file size should be 8MB'
//                 }
//             };
//             display_error_message(response);
//             return false;
//         } 
//     }

//     // Start uploading file

//     //sweet_alert_loading_box('Please wait','Uploading file is in progress...');

//     var pos = simplemde.codemirror.getCursor();
//     simplemde.codemirror.setSelection(pos, pos);

//     var data = new FormData();
//     var i = 0;
//     var completed = 0;
    
//     data.append('attachment', file);
//     $.ajax({
//         url: url,
//         type: 'POST',
//         contentType: false,
//         data: data,
//         processData: false,
//         cache: false,
//         dataType: 'json',
//         beforeSend: function(xhr) {
//             xhr.setRequestHeader('Authorization', accessToken);
//         },
//         success: function(response) {
//             if(response.response_data.success == true) {
//                 var filename = response.filename;
//                 var name = filename.substring(filename.lastIndexOf('/')+1);
//                 var zip_name = name.split('?')[0];
//                 var ext = name.split('.')[1];
//                 ext = ext.split('?')[0];
//                 //if(ext == 'zip' || ext == 'pdf') {
//                 if(ext == 'zip') {
//                     simplemde.codemirror.replaceSelection('['+zip_name+']('+response.filename+')\n');
//                 } else {
//                     simplemde.codemirror.replaceSelection('![Image]('+response.filename+')\n');
//                 }
//             } else {
//                 //display_error_message(response);
//             }
//         },
//         // complete: function() {
//         //     completed++;
//         //     if (completed === files.length) {
//         //         //swal.close();
//         //     }
//         // }
//     });
// }